import FormTextarea from "../components/Form/FormTextarea/FormTextarea"
import styles from "../styles/become-a-champion.module.css"
import FormInput from "../components/Form/FormInput/FormInput"
import React from "react"
import FormRadioButton from "../components/Form/FormRadioButton/FormRadioButton"

export const submitInputs = (inputs, sheetName) => {
    inputs.sheetname = sheetName
    const values = Object.keys(inputs).reduce((acc,curr) => `${acc}&${curr}=${String(inputs[curr]).trim()}`,'').slice(1);
    return fetch(`https://script.google.com/macros/s/AKfycbyVQJodwIxYbE8j-FEOF9txcKgXlhHwPO-HzbadE1mo1sfRvJg/exec?${values}`)
        .then(res => res.json())
        .then((e) => e.result === 'success')
}

export const getInputs =  (state, changed, inputRow = 1) => {
    return state.inputs.map(( 
        {
            label,
            id,
            type = 'text',
            style = {},
            elements,
            placeholder,
            tStyle = {},
            small = '',
            error = false,
            required = true,
            row = 1,
            radioLabel
            // eslint-disable-next-line
        }) => {
        if(inputRow === row){
            switch (type) {
                case 'textarea': {
                    return (
                        <FormTextarea
                            label={label}
                            value={state[id]}
                            changed={changed}
                            id={id}
                            required={required}
                            style={{ marginBottom: "44px", ...style }}
                            tStyle={tStyle}
                            key={id}
                            placeholder={placeholder}
                            error={error}
                            small={small}
                            name={id}
                        />
                    )
                }
                case 'row': {
                    return (
                        <div className={styles.row}>
                            {
                                elements.map(({label, id, type = 'text', style = {}, small = '', required = true, error}) => (
                                    <FormInput
                                        label={label}
                                        value={state[id]}
                                        changed={changed}
                                        id={id}
                                        required={required}
                                        style={{
                                            minWidth: '200px',
                                            flex: '1',
                                            marginBottom: "44px",
                                            ...style
                                        }}
                                        type={type}
                                        key={id}
                                        name={id}
                                        placeholder={placeholder}
                                        error={error}
                                        small={small}
                                    />
                                ))
                            }
                        </div>
                    )
                }
                case 'radio': {
                    return (
                        <div>
                            <p className={styles.radioLabel}>
                                {radioLabel}
                            </p>
                            <div className={[
                                styles.row,
                                styles.rowMargin
                            ].join(' ')}>
                                <FormRadioButton
                                    changed={() => changed(id,'Yes')}
                                    label={'Yes'}
                                    name={id}
                                    id={`${id}-yes`}
                                    style={{marginRight: '48px'}}
                                />
                                <FormRadioButton
                                    changed={() => changed(id,'No')}
                                    label={'No'}
                                    name={id}
                                    id={`${id}-no`}
                                />
                            </div>
                        </div>
                    )
                }
                default: {
                    return (
                        <FormInput
                            label={label}
                            value={state[id]}
                            changed={changed}
                            id={id}
                            required={required}
                            style={{ marginBottom: "44px", ...style }}
                            type={type}
                            name={id}
                            key={id}
                            error={error}
                            small={small}
                        />
                    )
                }
            }
        }
    })
}

export const getEmptyRequiredInputs = (state) => {
    const inputValues = {...state};
    delete inputValues.inputs
    delete inputValues.success
    const requiredInputs = [...state.inputs].reduce((allInputs,input) => input.type === 'row' ? [...allInputs,...input.elements] : [...allInputs,input],[]).filter(({required = true}) => required).reduce((acc,curr) => ([...acc,curr.id]),[]);
    const emptyRequiredInputs = Object.keys(inputValues).filter(input => inputValues[input] === '' && requiredInputs.find(i => i === input))
    if(emptyRequiredInputs.length){
        const inputs = [...state.inputs]
        emptyRequiredInputs.forEach(e => {
            // eslint-disable-next-line
            const currentInput = inputs.find((input) => {
                const {id,type} = input;
                if(type === 'row'){
                    const currentInput = input.elements.find(({id}) => id === e)
                    if(currentInput){
                        currentInput.error = true
                    }
                }else{
                    return id === e;
                }
            })
            if(currentInput){
                currentInput.error = true
            }
        })
        return [inputs,true]
    }else{
        return [inputValues,false]
    }
}