import React from "react"
import SuccessIcon from '../../../images/success.svg'
import { Link } from "gatsby"
import './FormSuccess.css'

function FormSuccess() {
    return (
        <div className={'success'}>
            <img src={SuccessIcon} alt="" className="success__image"/>
                <h3 className="success__header">
                SENT!
            </h3>
            <p className="success__subtext">
                Thanks for getting in touch, we'll get back to you soon.
            </p>
            <Link to='/' className="success__link">
                Go Home
            </Link>
        </div>
    )
}

export default FormSuccess