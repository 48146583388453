import React from "react"
import './FormRadioButton.css'

function FormRadioButton({id, label, style,name, changed }) {
    return (
        <label htmlFor={id} className='radio-button__label' style={style}>
            <input type="radio" id={id} name={name} className='radio-button__input' onChange={() => changed(name,id)}/>
            <span className='radio-button__indicator'/>
            <span className='radio-button__text'>{label}</span>
        </label>
    )
}

export default FormRadioButton