import React from "react"
import './FormInput.css'

function FormInput(
    {
       label,
       value,
       changed,
       id,
       required,
       style,
       type = 'text',
       placeholder = '',
       error = false,
       small = '',
       name=''
    }) {
    return (
        <div className="form-input" style={style}>
            <input
                type={type}
                onChange={(e) => {
                    changed(id,e.target.value)
                }}
                value={value}
                className="form-input__input"
                placeholder={placeholder}
                name={name}
                required
            />
            {label && (
                <label className={`form-input__label ${value && value.length && 'form-input__label--float'}`}>
                    {label}
                    {required && <span className='required'>*</span>}
                </label>
            )}
            {(small || error) && (<small className={`form-input__small ${error && 'form-input__small--error'}`}>
                {error ? 'Kindly fill this input.' : small}
            </small>)}
        </div>
    )
}

export default FormInput