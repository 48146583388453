import React, { Component } from "react"
import Navigation from "../components/Navigation/Navigation"
import FormButton from "../components/Form/FormButton/FormButton"
import FormRadioButton from "../components/Form/FormRadioButton/FormRadioButton"
import generalStyles from '../styles/split-page.module.css'
import styles from '../styles/become-a-champion.module.css'
import tech4devLogo from "../images/tech4dev-bdei.svg"
import SocialMediaIcons from "../components/SocialMediaIcons/SocialMediaIcons"
import FormSuccess from "../components/Form/FormSuccess/FormSuccess"
import { getEmptyRequiredInputs, getInputs, submitInputs } from "../utils/General"
import downIcon from "../images/down-arrow.svg"
import Header from "../components/Header/Header"

//TODO Clean up code
//TODO Isolate code into functions

class BecomeAChampion extends Component {
    state = {
        name: '',
        email: '',
        number: '',
        DOB: '',
        gender: '',
        occupation: '',
        position: '',
        country: '',
        state: '',
        proficiency: '',
        why: '',
        how: '',
        followers: '',
        success: false,
        committed: false,
        familiar: '',
        address: '',
        LGA: '',
        inputs: [
            {
                label: 'Name',
                id: 'name'
            },
            {
                label: 'Email',
                id: 'email'
            },
            {
                label: 'Phone Number',
                id: 'number'
            },
            {
                type: 'row',
                elements: [
                    {
                        label: 'Date of Birth',
                        id: 'DOB',
                        type: 'date',
                        style: {
                            marginRight: '5%'
                        }
                    },
                    {
                        label: 'Gender',
                        id: 'gender'
                    }
                ]
            },
            {
                label: 'Current Occupation',
                id: 'occupation'
            },
            {
                label: 'Position at Organization',
                id: 'position'
            },
            {
                type: 'row',
                elements: [
                    {
                        label: 'Country',
                        id: 'country',
                        style: {
                            marginRight: '4%'
                        }
                    },
                    {
                        label: 'State',
                        id: 'state'
                    }
                ]
            },
            {
                type: 'row',
                elements: [
                    {
                        label: 'Address',
                        id: 'address',
                        style: {
                            marginRight: '4%'
                        }
                    },
                    {
                        label: 'Local Government Area',
                        id: 'LGA'
                    }
                ]
            },
            {
                type: 'radio',
                id: 'familiar',
                radioLabel: 'Are you familiar with schools (Public and Private) in your area'
            },
            {
                label: 'What is your proficiency in Computer skills?',
                id: 'proficiency',
                type: 'textarea',
                small: 'Please list out all the skills you have here'
            },
            {
                label: 'Why do you want to be a part of BDEI?',
                id: 'why',
                type: 'textarea',
                small: 'What lessons would you love to take from the program'
            },
            {
                label: 'How have you helped to create positive change in your community or organization?',
                id: 'how',
                type: 'textarea',
                tStyle: {
                    height: '28px',
                    padding: '5px 0 4px'
                }
            },
            {
                label: 'How many followers do we currently have on our Instagram account?',
                id: 'followers',
                style: {
                    height: '38px'
                },
                type: 'number',
                row: 2
            }
        ]
    }

    form = React.createRef()

    changed = (id,value) => {
        this.setState({
            [id]: value
        })
    }

    submit = () => {
        const [processedInputs,error] = getEmptyRequiredInputs(this.state)
        if(error){
            this.setState({processedInputs})
            return false;
        }else{
            submitInputs(processedInputs,'champions').then(res => {
                if(res){
                    this.setState({success: true})
                }else{
                    console.log('err')
                }
            })
            return true
        }
    }

    radioChanged = (name,id) => {
        this.setState({
            [name]: id === 'yes'
        })
    }

    render() {
        return (
            <>
                <Header title={'Become a Champion | BDEI'}/>
                <Navigation dark fixed scrollWatch/>
                <div className={styles.container}>
                    <div className={styles.text}>
                        <div className={generalStyles.text__backdrop}/>
                        <h1 className={generalStyles.text__header}>
                            Are you a Champion?
                            Register Today.
                        </h1>
                        <p className={[generalStyles.text__subtext, styles.text__subtext].join(" ")}>
                            We are looking for individuals between the ages of 18 – 30 who are passionate about achieving SDG 4 & 10 (Quality Education & Reduced Inequalities) and are willing to volunteer their time to help children gain digital and stem skills, as well as access opportunities in the technology space.
                            <br/>
                            <span className={[generalStyles.below,styles.below].join(' ')}>
                                Kindly fill the form {' '}
                                <span
                                    onClick={() => this.form.current.scrollIntoView({behavior: 'smooth'})}
                                >
                                    below
                                </span>
                                <img src={downIcon} style={{width: '8px',marginLeft: '3px'}} alt=""/>
                            </span>
                        </p>
                        <a href="http://tech4dev.com/" target="_blank" className={styles.tech4devLink} rel="noopener noreferrer">
                            <p>An initiative of</p>
                            <img src={tech4devLogo} alt="tech4devlink"/>
                        </a>
                        <SocialMediaIcons className={styles.socialMediaIcons} right/>
                    </div>
                    {
                        !this.state.success ? (
                            <div className={styles.formContainer}  ref={this.form}>
                                <h1 className={generalStyles.form__header}>
                                    Become a Champion
                                </h1>
                                {
                                    getInputs(this.state,this.changed)
                                }
                                <p className={styles.form__subtext}>
                                    As a BDEI Champion, you would be required to commit at least 3 hours of your time weekly, would you love to continue the process?
                                </p>
                                <div className={styles.row} style={{marginBottom: '35px'}}>
                                    <FormRadioButton label='Yes' id='yes' name='committed' style={{marginRight: '48px'}} changed={this.radioChanged}/>
                                    <FormRadioButton label='No' id='no' name='committed' changed={this.radioChanged}/>
                                </div>
                                <p className={styles.form__subtext}>
                                    Please visit Tech4Dev’s Instagram account (<a href="https://www.instagram.com/tech4dev/" className={styles.form__subtextLink} target="_blank" rel="noopener noreferrer">(@Tech4Dev)</a>) and follow the page
                                </p>
                                <p className={styles.form__subheading}>
                                    QUIZ
                                </p>
                                {
                                    getInputs(this.state,this.changed,2)
                                }
                                <FormButton
                                    style={{width: '127px',marginBottom: '60px'}}
                                    submit={this.submit}
                                    active={this.state.committed}
                                >
                                    Submit
                                </FormButton>
                                <SocialMediaIcons dark right/>
                            </div>
                        ): <FormSuccess/>
                    }
                </div>
            </>
        )
    }
}

export default BecomeAChampion