import React, { Component } from "react"
import './FormTextarea.css'
import * as PropTypes from "prop-types"

class FormTextarea extends Component {
    constructor(props){
        super(props)
        this.textarea = React.createRef()
    }

    changed = () => {
        const textarea = this.textarea.current;
        textarea.style.height = '0'
        textarea.style.height = `${textarea.scrollHeight}px`
        const { id, changed } = this.props;
        changed(id, textarea.value)
    }

    render() {
        let { label, value, required, style, placeholder, tStyle = {}, small = '', error = false, name = '' } = this.props
        return (
            <div className='form-textarea' style={style}>
                <textarea
                    className='form-textarea__textarea'
                    value={value}
                    onChange={this.changed}
                    ref={this.textarea}
                    placeholder={placeholder}
                    style={{
                        maxHeight: '175px',
                        ...tStyle
                    }}
                    name={name}
                />
                {label && (
                    <label className={`form-textarea__label ${value && "form-textarea__label--float"}`}>
                        {label}
                        {required && <span className='required'>*</span>}
                    </label>
                )}
                {(small || error) && (<small className={`form-input__small ${error && 'form-input__small--error'}`}>
                    {error ? 'Kindly fill this input.' : small}
                </small>)}
            </div>
        )
    }
}

FormTextarea.propTypes = {
    label: PropTypes.any,
    value: PropTypes.any,
    changed: PropTypes.any,
    id: PropTypes.any,
    required: PropTypes.any,
    style: PropTypes.any
}

export default FormTextarea