import React from "react"
import "./FormButton.css"
import * as PropTypes from "prop-types"

function FormButton({ submit, children, style,active = true }) {
    return (
        <button
            style={style}
            className={`form-button ${!active && 'form-button--inactive'}`}
            onClick={(e) => handleSubmit(e.target, submit)}
            type="button"
            disabled={!active}
        >
            <span className="text">{children}</span>
            <span className="loader"/>
        </button>
    )
}

const handleSubmit = (button,func) => {
    button.disabled = func();
}

FormButton.propTypes = {
    children: PropTypes.any,
    style: PropTypes.any
}

export default FormButton